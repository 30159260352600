import React, { createContext, useCallback, useContext, useState } from 'react';

import { useToast } from '@chakra-ui/core';

import axios from 'axios';
import { HTTP } from '../constants';
import apiGateway from '../services/apiGateway';

interface IUser {
    id: string;
    name: string;
    email: string;
    avatar_url: string;
    permission?: string;
    account_verification?: {
        is_verified: boolean;
    };
}

interface IAuthState {
    token: string | null;
    user: IUser | null;
    branch: string | null;
    store: string | null;
    store_name: string | null;
}

interface ISignInCredentials {
    email: string;
    password: string;
}

interface IAuthContextData {
    user: IUser | null;
    branch: string | null;
    store: string | null;
    store_name: string | null;
    setSession: any;
    signIn(
        credentials: ISignInCredentials
    ): Promise<{ success: boolean; isNewDevice?: boolean; accountVerification?: any }>;
    signOut(): void;
    updateUser(updatedUser: any): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC<any> = ({ children }) => {
    const [session, setSession] = useState<IAuthState>(() => {
        return {
            token: null,
            user: null,
            branch: null,
            store: null,
            store_name: null
        };
    });

    const toast = useToast();

    const signOut = useCallback(() => {
        localStorage.removeItem('@eflorista:token');
        localStorage.removeItem('@eflorista:user');
        localStorage.removeItem('@eflorista:branch');
        localStorage.removeItem('@eflorista:store');
        localStorage.removeItem('@eflorista:store_name');

        setSession({} as IAuthState);
    }, []);

    const updateUser = useCallback((updatedUser) => {
        setSession((oldSession) => {
            return {
                ...oldSession,
                user: updatedUser
            };
        });
    }, []);

    const signIn = useCallback(
        async ({ email, password }) => {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth`, {
                email,
                password
            });

            if (response.status === HTTP.STATUS.SUCCESS) {
                const {
                    token,
                    user,
                    branch,
                    store,
                    store_name,
                    is_new_device,
                    account_verification
                } = response.data;

                localStorage.setItem('@eflorista:token', token);
                localStorage.setItem('@eflorista:user', JSON.stringify(user));
                localStorage.setItem('@eflorista:branch', branch);
                localStorage.setItem('@eflorista:store', store);
                localStorage.setItem('@eflorista:store_name', store_name);

                apiGateway.defaults.headers.authorization = `Bearer ${token}`;

                setSession({ token, user, branch, store, store_name });

                return {
                    success: true,
                    isNewDevice: is_new_device,
                    accountVerification: account_verification
                };
            } else {
                return { success: false };
            }
        },
        [toast]
    );

    return (
        <AuthContext.Provider
            value={{
                user: session.user,
                branch: session.branch,
                store: session.store,
                store_name: session.store_name,
                setSession,
                signIn,
                signOut,
                updateUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): IAuthContextData {
    const context = useContext(AuthContext);
    return context;
}

export { AuthProvider, useAuth };
