import React, { useCallback, useRef, useState } from 'react';

import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast
} from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import Form from '../../../components/Form';
import Input from '../../../components/Form/Input';
import VerificationCodeInput from '../../../components/Form/VerificationInput';
import { HTTP } from '../../../shared/constants';
import { useAuth } from '../../../shared/hooks/useAuth';
import apiGateway from '../../../shared/services/apiGateway';

interface IProps {
    isOpen: any;
    onClose: any;
}

type StepType = 'VERIFICATION' | 'CONFIRMATION' | 'FINALIZATION';

const ModalAccountConfirmation: React.FC<IProps> = ({ isOpen, onClose }) => {
    const formRef = useRef<FormHandles>(undefined);
    const toast = useToast();
    const { user, updateUser } = useAuth();

    const [step, setStep] = useState<StepType>('VERIFICATION');

    const [showRetry, setShowRetry] = useState(false);

    const handleSubmit = useCallback(async () => {
        if (step === 'CONFIRMATION') {
            handleConfirm();
            return;
        }

        const email = formRef.current.getFieldValue('email');
        const telephone = formRef.current.getFieldValue('telephone');

        if (!email || !telephone) {
            toast({
                title: 'Campos em Branco',
                description: 'Preencha todos os campos.',
                status: 'error',
                duration: 2000
            });
            return;
        }

        const { status } = await apiGateway.post('/users/auth/account_verification', {
            email,
            telephone,
            user_id: user.id
        });

        if (status !== HTTP.STATUS.CREATED) {
            toast({
                title: 'Ocorreu um problema',
                description: 'Não foi possível atualizar os dados.',
                status: 'error',
                duration: 2000
            });
            return;
        }

        setStep('CONFIRMATION');
        setTimeout(() => {
            setShowRetry(true);
        }, 45000);
    }, [user, step]);

    const handleConfirm = useCallback(async () => {
        if (!user) return;

        const { status, data } = await apiGateway.put('/users/auth/account_verification', {
            verification_code: formRef.current.getFieldValue('code_verification'),
            email: formRef.current.getFieldValue('email'),
            telephone: formRef.current.getFieldValue('telephone'),
            user_id: user.id
        });

        if (status !== HTTP.STATUS.SUCCESS) {
            toast({
                title: 'Ocorreu um problema',
                description: 'Não foi possível verificar sua conta.',
                status: 'error',
                duration: 2000
            });
            setShowRetry(true);
            return;
        }

        onClose();

        toast({
            title: 'Verificação Completa',
            description: 'Sua conta foi verificada.',
            status: 'success',
            duration: 2000
        });

        updateUser(data);
    }, [user, formRef]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered initialFocusRef={null}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="500" pb="0px">
                        Verifique sua conta
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody flexDirection="column">
                        <Flex width="100%" flexDirection="column">
                            {step === 'VERIFICATION' && (
                                <Text fontSize="16px">
                                    Informe e-mail e telefone e enviaremos um código para você.
                                </Text>
                            )}

                            {step === 'CONFIRMATION' && (
                                <Text fontSize="16px">Enviamos um código para seu whatsapp.</Text>
                            )}
                        </Flex>

                        <Form ref={formRef as any} onSubmit={() => handleSubmit}>
                            {step === 'VERIFICATION' && (
                                <>
                                    <Flex width="100%" fontSize="12px" mt="8px">
                                        <Input
                                            fontSize="14px"
                                            label="E-mail"
                                            name="email"
                                            size="sm"
                                        />
                                    </Flex>

                                    <Flex width="100%" fontSize="12px" mt="8px">
                                        <Input
                                            fontSize="14px"
                                            label="Telefone/Whatsapp"
                                            name="telephone"
                                            size="sm"
                                            mask="(99) 99999-9999"
                                        />
                                    </Flex>
                                </>
                            )}

                            {step === 'CONFIRMATION' && (
                                <Flex
                                    width="100%"
                                    flexDirection="column"
                                    alignItems="center"
                                    my="16px"
                                    mb="24px"
                                >
                                    <VerificationCodeInput name="verification_code" />

                                    {!showRetry && (
                                        <Text fontWeight="400" fontSize="14px" mt="8px">
                                            Tente novamente em 45s...
                                        </Text>
                                    )}

                                    {showRetry && (
                                        <Text
                                            onClick={() => {
                                                setShowRetry(false);
                                                setStep('VERIFICATION');
                                            }}
                                            color="blue.500"
                                            mt="8px"
                                            cursor="pointer"
                                        >
                                            Tentar Novamente
                                        </Text>
                                    )}
                                </Flex>
                            )}
                            <Flex
                                width="100%"
                                flexDirection="row"
                                justifyContent="space-between"
                                mt="16px"
                                mb="8px"
                                fontWeight="500"
                            >
                                <Button
                                    width="48%"
                                    backgroundColor="white"
                                    _hover={{
                                        backgroundColor: 'white'
                                    }}
                                    color="red.500"
                                    fontWeight="500"
                                    onClick={() => onClose()}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    width="48%"
                                    backgroundColor="blue.500"
                                    color="white"
                                    fontWeight="500"
                                    _hover={{
                                        backgroundColor: 'blue.600'
                                    }}
                                    onClick={() => handleSubmit()}
                                >
                                    Confirmar
                                </Button>
                            </Flex>
                        </Form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalAccountConfirmation;
