import { Box, Flex, Spinner, Text } from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import apiGateway from '../../../../shared/services/apiGateway';

const VideoPreview = ({ urlVideo, setVideoUrl, preLoading = true }: any) => {
    const [isLoading, setIsLoading] = useState(preLoading);

    const handleDelete = useCallback(async () => {
        await apiGateway.delete(`/upload/video/${urlVideo.replace('/videos/', '')}`);
        setVideoUrl('');
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 60000);
    }, []);

    if (isLoading) {
        return (
            <Flex
                width="200px"
                height="200px"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Spinner />
                <Text fontSize="14px" textAlign="center">
                    Aguarde estamos processando seu vídeo...
                </Text>
            </Flex>
        );
    }

    return (
        !isLoading &&
        urlVideo && (
            <Flex position="relative">
                <Box
                    p="4px"
                    backgroundColor="pink.500"
                    color="white"
                    borderRadius="2px"
                    position="absolute"
                    top="0px"
                    right="-10px"
                    title="Excluir Vídeo"
                    cursor="pointer"
                    onClick={() => handleDelete()}
                >
                    <FiTrash2 size={16} />
                </Box>
                <iframe
                    src={`https://player.vimeo.com${urlVideo.replace('/videos', '/video')}`}
                    width="200"
                    height="200"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    title="Vídeo"
                />
            </Flex>
        )
    );
};

export default VideoPreview;
