import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
    AiOutlineAppstoreAdd,
    AiOutlineCluster,
    AiOutlineColumnHeight,
    AiOutlineEdit
} from 'react-icons/ai';
import { FaPlus, FaRegImages, FaTrash } from 'react-icons/fa';
import { FiTrendingUp, FiTruck } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Divider, Flex, Radio, Stack, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import InputCurrency from '../../../../components/Form/InputCurrency';
import Select from '../../../../components/Form/Select';
import TextArea from '../../../../components/Form/TextArea';
import TextEditor from '../../../../components/Form/TextEditor';
import { HTTP } from '../../../../shared/constants';
import { useAuth } from '../../../../shared/hooks/useAuth';
import apiGateway from '../../../../shared/services/apiGateway';
import NumberUtils from '../../../../shared/utils/NumberUtils';
import SelectCategories from '../../components/Form/SelectCategories';
import SelectSubtypes from '../../components/Form/SelectSubtypes';
import SelectTags from '../../components/Form/SelectTags';
import UploadProductImage from '../../components/Form/UploadProductImage';
import UploadVariantImage from '../../components/Form/UploadVariantImage';
import VideoPreview from '../../components/VideoPreview';
import { useProductForm } from '../../contexts/ProductFormProvider';
import ProductFormHelper from '../../helpers/ProductFormHelper';

const ProductEdit: React.FC = () => {
    const { id } = useParams() as any;
    const navigate = useNavigate();

    const [product, setProduct] = useState(undefined);
    const {
        setEnable,
        setPromotional,
        setFreeShipping,
        addImages,
        addTypes,
        addSubtypes,
        addTags,
        addVariant,
        addListVariants,
        editVariant,
        removeVariants,
        getAttributes
    } = useProductForm();

    const { images, types, is_enable, is_promotional, is_free_shipping, variants } =
        getAttributes();

    const formRef = useRef<FormHandles>(undefined);
    const toast = useToast();

    const { branch } = useAuth();
    const [defaultMultiTypes, setDefaultMultiTypes] = useState(null);
    const [showVariants, setShowVariants] = useState(false);
    const [isStock, setIsStock] = useState(false);
    const [urlVideo, setUrlVideo] = useState('');

    const [generalSettings, setGeneralSettings] = useState(null);
    const [videoSettings, setVideoSettings] = useState(null);
    const [preLoadingVideo, setPreloadingVideo] = useState(false);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const { categories, subtypes, tags } = getAttributes();

                const productData = await ProductFormHelper.makeValidProductDataToSend({
                    formData,
                    is_enable,
                    is_promotional,
                    is_free_shipping,
                    tags,
                    images,
                    categories,
                    types,
                    subtypes,
                    cover_id: images.find((image) => image?.is_cover === true)?.id,
                    stock: {
                        quantity: formData.num_stock,
                        branch_id: branch
                    }
                });

                productData.variants = variants;

                const checkVariants = [...variants.colors, ...variants.sizes];

                if (checkVariants && checkVariants.length > 0) {
                    let shouldContinue = true;
                    checkVariants.forEach((variant) => {
                        if (variant.price <= 0) {
                            ProductFormHelper.onError(
                                'Registro não efetuado',
                                'A variante do produto precisa ter um preço válido',
                                toast
                            );

                            shouldContinue = false;
                        }
                    });

                    if (!shouldContinue) return;
                }

                apiGateway
                    .put(`/products/${id}`, {
                        ...productData,
                        url_video: urlVideo,
                        is_stock: isStock
                    })
                    .then((response) => {
                        if (response.status === HTTP.STATUS.SUCCESS) {
                            ProductFormHelper.onSuccess(
                                'Registro atualizado',
                                'Produto atualizado com sucesso',
                                toast
                            );

                            navigate('/products');
                        }
                    });
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    ProductFormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [
            branch,
            getAttributes,
            navigate,
            id,
            images,
            is_enable,
            is_free_shipping,
            is_promotional,
            toast,
            types,
            variants,
            isStock,
            urlVideo
        ]
    );

    const handleCalcMarcap = useCallback(() => {
        const costValue = formRef.current.getFieldValue('price_purchase');
        const marcapValue = formRef.current.getFieldValue('marcap');

        if (costValue && marcapValue) {
            const value1 = parseFloat(costValue.replace(/\./g, '').replace(',', '.')).toFixed(2);

            const value2 = parseFloat(marcapValue.replace(/\./g, '').replace(',', '.')).toFixed(2);

            const result = Number(value1) + Number(value1) * (Number(value2) / 100);

            formRef.current.getFieldRef('price_sale').value = Number(result).toLocaleString(
                'pt-BR',
                {
                    minimumFractionDigits: 2
                }
            );
        }
    }, []);

    const handleChangeName = useCallback(() => {
        const name = formRef.current.getFieldValue('name');

        formRef.current.getFieldRef('slug').value = name;
    }, []);

    const handleTypeChange = useCallback(
        (value) => {
            addTypes([value], null);
        },
        [addTypes]
    );

    const handleEditVariant = useCallback(
        (variantName) => {
            const { value } = formRef.current.getFieldRef(`${variantName}_price`);

            editVariant(variantName, 'price', NumberUtils.formatNumber(value));
        },
        [editVariant]
    );

    const handleUploadVideo = useCallback(async (file: any) => {
        setPreloadingVideo(true);

        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        console.log(formData);

        const { status, data } = await apiGateway.post('/upload/video', formData);

        if (status === HTTP.STATUS.SUCCESS) {
            setUrlVideo(data.video_url);
        }
    }, []);

    useEffect(() => {
        apiGateway.get('/products/types?query').then((response) => {
            const typesResponse = response.data;

            setDefaultMultiTypes(
                typesResponse.map((type) => {
                    return {
                        id: type.id,
                        name: type.value,
                        text: type.value
                    };
                })
            );
        });

        apiGateway.get(`/products/${id}`).then((response) => {
            const productResponse = response.data;

            setProduct(productResponse);
            setEnable(productResponse.is_enable);
            setPromotional(productResponse.is_promotional);
            setFreeShipping(productResponse.is_free_shipping);
            setIsStock(productResponse.is_stock);
            setUrlVideo(productResponse.url_video);

            productResponse.images.forEach((item) => {
                addImages({
                    key: Math.random().toString(36).substr(2, 9),
                    id: item.id,
                    file: item.file,
                    name: item.file,
                    preview: item.url_thumb,
                    progress: 100,
                    uploaded: true,
                    error: false,
                    url: item.url_thumb,
                    is_cover: item.id === productResponse.cover.id
                } as any);
            });

            if (productResponse.types.length > 0) {
                addTypes(
                    productResponse.types.map((item) => item.id),
                    null
                );
            }

            addTags(
                productResponse.tags.map((item) => item.id),
                []
            );

            addSubtypes(
                productResponse.subtypes.map((item) => item.id),
                []
            );

            if (
                productResponse?.variants?.sizes.length > 0 ||
                productResponse?.variants?.colors.length > 0
            ) {
                setShowVariants(true);
            }

            addListVariants(
                productResponse?.variants || {
                    sizes: [],
                    colors: []
                }
            );
        });
    }, [
        addImages,
        addListVariants,
        addSubtypes,
        addTags,
        addTypes,
        id,
        setEnable,
        setFreeShipping,
        setPromotional
    ]);

    useEffect(() => {
        apiGateway.get('/settings/general_settings').then((response) => {
            setGeneralSettings(response.data);
        });

        apiGateway.get('/settings/video').then((response) => {
            if (response.status === 200) {
                setVideoSettings(response.data);
            }
        });
    }, []);

    console.log(types);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            {product && (
                <Flex
                    width="100%"
                    maxWidth="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontWeight="500"
                    fontSize="14px"
                >
                    <Flex width="100%">
                        <Flex width="100%" justifyContent="space-between">
                            <Flex flexDirection="column">
                                <Text>Selecione o tipo de produto</Text>
                                <Stack direction="row" mt="8px" mb="24px" spacing={8}>
                                    {defaultMultiTypes &&
                                        defaultMultiTypes.map((type) => (
                                            <Flex flexDirection="column" key={type.id}>
                                                {(generalSettings?.custom_card ||
                                                    type.name !== 'Cartão Custom') && (
                                                    <Radio
                                                        key={type.id}
                                                        size="md"
                                                        name={`product_type_${type.id}`}
                                                        variantColor="green"
                                                        isChecked={types.ids.includes(type.id)}
                                                        defaultIsChecked={types.ids.includes(
                                                            type.id
                                                        )}
                                                        value={type.id}
                                                        onFocus={(e) => {
                                                            handleTypeChange(type.id);
                                                        }}
                                                    >
                                                        <Text fontSize="14px">{type.name}</Text>
                                                    </Radio>
                                                )}
                                                {type.name === 'Padrão' && (
                                                    <Text fontSize="12px" color="red.500">
                                                        Buquês, cestas, arranjos...
                                                    </Text>
                                                )}

                                                {type.name === 'Complemento' && (
                                                    <Text fontSize="12px" color="red.500">
                                                        Vinhos, chocolates, ursinhos...
                                                    </Text>
                                                )}

                                                {type.name === 'Monte sua Cesta' && (
                                                    <Text fontSize="12px" color="red.500">
                                                        Item para o monte sua cesta
                                                    </Text>
                                                )}

                                                {type.name === 'Cartão Custom' && (
                                                    <Text fontSize="12px" color="red.500">
                                                        Cartão Customizado
                                                    </Text>
                                                )}
                                            </Flex>
                                        ))}
                                </Stack>
                            </Flex>
                            <Box fontWeight="500" mr="48px">
                                <Text>Mostrar no site</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={is_enable === false}
                                        onChange={() => setEnable(!is_enable)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={is_enable}
                                        onChange={() => setEnable(!is_enable)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                    <Flex alignItems="center" justifyContent="space-between">
                        <Flex mt="12px" mr="8px" color="purple.500">
                            <FaRegImages size={24} />
                            <Text color="purple.500" fontSize="20px" ml="8px">
                                Fotos
                            </Text>
                        </Flex>

                        {videoSettings && (
                            <Flex color="purple.500">
                                <FaRegImages size={24} />
                                <Text color="purple.500" fontSize="20px" ml="8px">
                                    Vídeo
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                    <Box display="flex" width="100%" mt="8px">
                        {images.map((image) => (
                            <UploadProductImage key={image.id} defaultImage={image} />
                        ))}
                        {images.length <= 0 && (
                            <>
                                <UploadProductImage />
                                <UploadProductImage />
                                <UploadProductImage />
                                <UploadProductImage />
                            </>
                        )}

                        {images.length === 1 && (
                            <>
                                <UploadProductImage />
                                <UploadProductImage />
                                <UploadProductImage />
                            </>
                        )}

                        {images.length === 2 && (
                            <>
                                <UploadProductImage />
                                <UploadProductImage />
                            </>
                        )}

                        {images.length === 3 && (
                            <>
                                <UploadProductImage />
                            </>
                        )}
                        <Flex flex="1" justifyContent="flex-end">
                            {videoSettings && (
                                <Flex flexDirection="column">
                                    <Flex flexDirection="column">
                                        {urlVideo && (
                                            <VideoPreview
                                                urlVideo={urlVideo}
                                                setVideoUrl={setUrlVideo}
                                                preLoading={preLoadingVideo}
                                            />
                                        )}
                                    </Flex>
                                    {videoSettings && !urlVideo && (
                                        <Box display="flex" width="100%" mt="8px">
                                            <Input
                                                typeInput="file"
                                                name="Video"
                                                onChange={(e) =>
                                                    handleUploadVideo(e.target.files[0])
                                                }
                                                pt="4px"
                                            />
                                        </Box>
                                    )}
                                </Flex>
                            )}
                        </Flex>
                    </Box>

                    <Divider mt="24px" mb="16px" />

                    <Flex alignItems="center">
                        <Flex mr="8px" color="purple.500">
                            <AiOutlineEdit size={24} />
                        </Flex>
                        <Text color="purple.500" fontSize="20px">
                            Informações Básicas
                        </Text>
                    </Flex>
                    <Flex width="100%" justifyContent="space-between" flexWrap="wrap" mt="8px">
                        <Box width="32%">
                            <Input
                                name="code_sku"
                                label="Código"
                                isRequired
                                size="sm"
                                defaultValue={product.code_sku}
                            />
                        </Box>
                        <Box width="32%">
                            <Input
                                name="name"
                                label="Nome"
                                isRequired
                                size="sm"
                                defaultValue={product.name}
                                onChange={() => handleChangeName()}
                            />
                        </Box>
                        <Box width="32%">
                            <Input
                                name="slug"
                                label="Slug"
                                isRequired
                                size="sm"
                                defaultValue={product.slug}
                                tooltip
                                tooltipMessage="O slug é utilizado na url da pagina de detalhes do produto Ex: meusite.com.br/slug-do-produto."
                            />
                        </Box>

                        <Box width="20%">
                            <InputCurrency
                                name="price_purchase"
                                label="Valor de Custo"
                                size="sm"
                                defaultValue={product.price_purchase}
                                onChange={() => handleCalcMarcap()}
                            />
                        </Box>

                        <Box width="20%">
                            <InputCurrency
                                defaultValue={product.marcap}
                                name="marcap"
                                label="Marcap %  (Margem)"
                                size="sm"
                                badge="porcentage"
                                onChange={() => handleCalcMarcap()}
                                tooltip
                                tooltipMessage="O marcap é utilizado para calcular o valor de venda baseado no custo do produto e a porcentagem do marcap."
                            />
                        </Box>

                        <Box width="20%">
                            <InputCurrency
                                name="price_sale"
                                label="Valor Venda"
                                size="sm"
                                isRequired
                                defaultValue={product.price_sale}
                            />
                        </Box>

                        <Box width="20%">
                            <InputCurrency
                                name="price_promotional"
                                label="Valor Promocional"
                                size="sm"
                                defaultValue={product.price_promotional}
                            />
                        </Box>

                        <Flex width="100%">
                            <Box fontWeight="500" mr="24px">
                                <Text>Gerenciar Estoque?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_promotional"
                                        isChecked={isStock === false}
                                        onChange={() => setIsStock(!isStock)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_promotional"
                                        isChecked={isStock}
                                        onChange={() => setIsStock(!isStock)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>

                            <Box width="144px">
                                <Input
                                    name="num_stock"
                                    label="Unidades em Estoque"
                                    size="sm"
                                    defaultValue={product?.stock?.quantity}
                                />
                            </Box>
                        </Flex>

                        <Flex width="100%">
                            <TextEditor
                                name="description"
                                label="Descrição"
                                defaultValue={product.description}
                                isRequired
                                minHeight="200px"
                            />
                        </Flex>

                        <Flex width="100%">
                            <Box fontWeight="500">
                                <Text>Usar preço promocional ?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_promotional"
                                        isChecked={is_promotional === false}
                                        onChange={() => setPromotional(!is_promotional)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_promotional"
                                        isChecked={is_promotional}
                                        onChange={() => setPromotional(!is_promotional)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>

                    <Divider mt="24px" mb="16px" />

                    <Flex alignItems="center">
                        <Flex mr="8px" color="purple.500">
                            <FiTruck size={24} />
                        </Flex>
                        <Text color="purple.500" fontSize="20px">
                            Frete / Entregas
                        </Text>
                    </Flex>
                    <Flex width="100%" mt="8px">
                        <Box width="32%">
                            <InputCurrency
                                name="extra_shipping_value"
                                placeholder="Ex: 10%"
                                label="Acrescentar valor do frete %"
                                size="sm"
                                defaultValue={product.extra_shipping_value}
                                badge="porcentage"
                                textAlign="left"
                            />
                        </Box>

                        <Box width="32%" fontWeight="500" ml="24px">
                            <Text>Frete grátis ?</Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                maxWidth="80px"
                                alignItems="flex-end"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_free_shipping"
                                    isChecked={is_free_shipping === false}
                                    onChange={() => setFreeShipping(!is_free_shipping)}
                                    value="0"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>
                                <Checkbox
                                    name="is_free_shipping"
                                    isChecked={is_free_shipping}
                                    onChange={() => setFreeShipping(!is_free_shipping)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>

                    <Divider mt="24px" mb="16px" />

                    <Flex alignItems="center">
                        <Flex mr="8px" color="purple.500">
                            <FiTrendingUp size={24} />
                        </Flex>
                        <Text color="purple.500" fontSize="20px">
                            SEO
                        </Text>
                    </Flex>
                    <Flex width="100%" mt="8px" flexWrap="wrap" justifyContent="space-between">
                        <Box width="48%">
                            <Input
                                name="title"
                                label="Título"
                                size="sm"
                                isRequired
                                defaultValue={product.title}
                            />
                        </Box>

                        <Flex width="48%">
                            <Box width="100%">
                                <SelectTags
                                    defaultMultiSelect={product.tags.map((item) => {
                                        return {
                                            id: item.id,
                                            text: item.value
                                        };
                                    })}
                                />
                            </Box>
                        </Flex>

                        <Box width="100%">
                            <TextArea
                                name="meta_description"
                                label="Meta Description"
                                isRequired
                                size="sm"
                                mb="4px"
                                defaultValue={product.meta_description}
                            />
                            <Text fontSize="10px" width="100%" textAlign="right" color="gray.600">
                                Núm caractéres 1452. (Meta: 200 a 230)
                            </Text>
                        </Box>
                    </Flex>

                    <Divider mt="24px" mb="16px" />

                    <Flex alignItems="center">
                        <Flex mr="8px" color="purple.500">
                            <AiOutlineColumnHeight size={24} />
                        </Flex>
                        <Text color="purple.500" fontSize="20px">
                            Medidas
                        </Text>
                    </Flex>

                    <Flex width="100%" mt="8px" flexWrap="wrap">
                        <Box width="120px" mr="16px">
                            <Input
                                name="width"
                                label="Largura (CM)"
                                size="sm"
                                defaultValue={product.width}
                            />
                        </Box>

                        <Box width="120px">
                            <Input
                                name="height"
                                label="Altura (CM)"
                                size="sm"
                                defaultValue={product.height}
                            />
                        </Box>
                    </Flex>

                    <Divider mt="24px" mb="16px" />

                    <Flex alignItems="center">
                        <Flex mr="8px" color="purple.500">
                            <AiOutlineCluster size={24} />
                        </Flex>
                        <Text color="purple.500" fontSize="20px">
                            Categorias
                        </Text>
                    </Flex>
                    <Flex width="100%" mt="8px" justifyContent="space-between" flexWrap="wrap">
                        <Box width="48%">
                            <SelectCategories
                                defaultMultiSelect={product.categories.map((item) => {
                                    return {
                                        id: item.id,
                                        text: item.name
                                    };
                                })}
                            />
                        </Box>

                        {types.ids.includes(
                            defaultMultiTypes?.find((item) => item.name === 'Monte sua Cesta')?.id
                        ) && (
                            <Box width="48%">
                                <SelectSubtypes
                                    defaultMultiSelect={product.subtypes.map((item) => {
                                        return {
                                            id: item.id,
                                            text: item.value
                                        };
                                    })}
                                />
                            </Box>
                        )}
                    </Flex>

                    <Divider mt="24px" mb="16px" />

                    <Flex width="100%" flexDirection="column">
                        <Flex width="100%" justifyContent="space-between">
                            <Flex alignItems="center">
                                <Flex mr="8px" color="purple.500">
                                    <AiOutlineAppstoreAdd size={24} />
                                </Flex>
                                <Text color="purple.500" fontSize="20px">
                                    Variações
                                </Text>
                            </Flex>

                            <Box fontWeight="500" mr="48px">
                                <Text>Usar Variações</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={showVariants === false}
                                        onChange={() => setShowVariants(!showVariants)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={showVariants}
                                        onChange={() => setShowVariants(!showVariants)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>

                        {showVariants && (
                            <Flex width="100%" flexDirection="column">
                                <Flex alignItems="center">
                                    <Flex width="100%" maxWidth="144px" mr="8px">
                                        <Select
                                            label="Tipo de Variação"
                                            name="variant_type"
                                            size="sm"
                                        >
                                            <option value="color">Cor</option>
                                            <option value="size">Tamanho</option>
                                        </Select>
                                    </Flex>

                                    <Flex width="100%" maxWidth="184px" mr="8px">
                                        <Input
                                            label="Nome da Variação"
                                            name="variant_name"
                                            placeholder="Ex: Vermelho"
                                            size="sm"
                                        />
                                    </Flex>

                                    <Flex
                                        width="32px"
                                        height="32px"
                                        backgroundColor="green.500"
                                        color="white"
                                        borderRadius="4px"
                                        justifyContent="center"
                                        alignItems="center"
                                        mt="8px"
                                        cursor="pointer"
                                        onClick={() => addVariant(formRef)}
                                    >
                                        <FaPlus />
                                    </Flex>
                                </Flex>

                                <Flex flexDirection="column" mt="16px">
                                    <Text color="purple.500">Tamanhos</Text>

                                    <Flex
                                        border="2px solid"
                                        borderColor="gray.500"
                                        py="8px"
                                        px="16px"
                                        mt="8px"
                                        flexDirection="column"
                                    >
                                        {variants?.sizes.length === 0 && (
                                            <Text color="gray.700">Nenhum tamanho cadastrado</Text>
                                        )}

                                        {variants?.sizes.length > 0 && (
                                            <Flex width="100%" flexDirection="column">
                                                <Flex width="100%" justifyContent="space-between">
                                                    <Text width="100%" maxWidth="96px">
                                                        Imagem
                                                    </Text>
                                                    <Text width="100%">Variação</Text>
                                                    <Text width="100%" maxWidth="96px">
                                                        Estoque
                                                    </Text>
                                                    <Text width="100%" maxWidth="120px">
                                                        Preço
                                                    </Text>
                                                    <Text width="100%" maxWidth="80px">
                                                        Ação
                                                    </Text>
                                                </Flex>

                                                {variants?.sizes.map((color) => (
                                                    <Flex
                                                        key={color.id}
                                                        py="8px"
                                                        mt="8px"
                                                        alignItems="center"
                                                        style={{
                                                            justifyContent: 'space-between',
                                                            gap: '16px'
                                                        }}
                                                    >
                                                        <Flex
                                                            width="100%"
                                                            maxWidth="96px"
                                                            alignItems="center"
                                                        >
                                                            <UploadVariantImage
                                                                variantName={color.name}
                                                                defaultImage={color ? color : null}
                                                            />
                                                        </Flex>

                                                        <Flex width="100%">
                                                            <Text>{color.name}</Text>
                                                        </Flex>

                                                        <Flex width="100%" maxWidth="96px">
                                                            <Input
                                                                name="variant_quantity"
                                                                defaultValue={color.quantity}
                                                                size="sm"
                                                                mb="0px"
                                                                onChange={(
                                                                    e: ChangeEvent<HTMLInputElement>
                                                                ) =>
                                                                    editVariant(
                                                                        color.name,
                                                                        'quantity',
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                        </Flex>

                                                        <Flex width="100%" maxWidth="120px">
                                                            <InputCurrency
                                                                name={`${color.name}_price`}
                                                                defaultValue={color.price}
                                                                size="sm"
                                                                mb="0px"
                                                                onChange={() =>
                                                                    handleEditVariant(color.name)
                                                                }
                                                            />
                                                        </Flex>

                                                        <Flex width="100%" maxWidth="80px">
                                                            <Flex
                                                                backgroundColor="red.500"
                                                                color="white"
                                                                width="24px"
                                                                height="24px"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                borderRadius="4px"
                                                                cursor="pointer"
                                                                onClick={() =>
                                                                    removeVariants(color.name)
                                                                }
                                                            >
                                                                <FaTrash />
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                ))}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>

                                <Flex flexDirection="column" mt="16px">
                                    <Text color="purple.500">Cores</Text>

                                    <Flex
                                        border="2px solid"
                                        borderColor="gray.500"
                                        py="8px"
                                        px="16px"
                                        mt="8px"
                                        flexDirection="column"
                                    >
                                        {variants?.colors.length === 0 && (
                                            <Text color="gray.700">Nenhuma cor cadastrada</Text>
                                        )}

                                        {variants?.colors.length > 0 && (
                                            <Flex width="100%" flexDirection="column">
                                                <Flex width="100%" justifyContent="space-between">
                                                    <Text width="100%" maxWidth="96px">
                                                        Imagem
                                                    </Text>
                                                    <Text width="100%">Variação</Text>
                                                    <Text width="100%" maxWidth="96px">
                                                        Estoque
                                                    </Text>
                                                    <Text width="100%" maxWidth="120px">
                                                        Preço
                                                    </Text>
                                                    <Text width="100%" maxWidth="80px">
                                                        Ação
                                                    </Text>
                                                </Flex>

                                                {variants?.colors.map((color) => (
                                                    <Flex
                                                        key={color.id}
                                                        py="8px"
                                                        mt="8px"
                                                        alignItems="center"
                                                        style={{
                                                            justifyContent: 'space-between',
                                                            gap: '16px'
                                                        }}
                                                    >
                                                        <Flex
                                                            width="100%"
                                                            maxWidth="96px"
                                                            alignItems="center"
                                                        >
                                                            <UploadVariantImage
                                                                variantName={color.name}
                                                                defaultImage={color ? color : null}
                                                            />
                                                        </Flex>

                                                        <Flex width="100%">
                                                            <Text>{color.name}</Text>
                                                        </Flex>

                                                        <Flex width="100%" maxWidth="96px">
                                                            <Input
                                                                name="variant_quantity"
                                                                defaultValue={color.quantity}
                                                                size="sm"
                                                                mb="0px"
                                                                onChange={(
                                                                    e: ChangeEvent<HTMLInputElement>
                                                                ) =>
                                                                    editVariant(
                                                                        color.name,
                                                                        'quantity',
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                        </Flex>

                                                        <Flex width="100%" maxWidth="120px">
                                                            <InputCurrency
                                                                name={`${color.name}_price`}
                                                                defaultValue={color.price}
                                                                size="sm"
                                                                mb="0px"
                                                                onChange={() =>
                                                                    handleEditVariant(color.name)
                                                                }
                                                            />
                                                        </Flex>

                                                        <Flex width="100%" maxWidth="80px">
                                                            <Flex
                                                                backgroundColor="red.500"
                                                                color="white"
                                                                width="24px"
                                                                height="24px"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                borderRadius="4px"
                                                                cursor="pointer"
                                                                onClick={() =>
                                                                    removeVariants(color.name)
                                                                }
                                                            >
                                                                <FaTrash />
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                ))}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>

                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
                        Editar
                    </Button>
                </Flex>
            )}
        </Form>
    );
};

export default ProductEdit;
