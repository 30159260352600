import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Heading, Image, PseudoBox, Text, useToast } from '@chakra-ui/core';
import { Form } from '@unform/web';

import { FormHandles } from '@unform/core';
import logo from '../../../../assets/images/logo-eflorista-novo.png';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import VerificationCodeInput from '../../../../components/Form/VerificationInput';
import Loader from '../../../../shared/components/Loader';
import { useAuth } from '../../../../shared/hooks/useAuth';

type DataSubmit = {
    email: string;
    password: string;
};

const SignIn: React.FC = () => {
    const navigate = useNavigate();
    const { signIn } = useAuth();

    const formRef = useRef<FormHandles>(undefined);
    const [loading, setLoading] = useState(false);
    const [verificationDevice, setVerificationDevice] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');

    const handleToForgotPassword = useCallback(() => {
        navigate('/forgot-password');
    }, [navigate]);

    const toast = useToast();

    const handleConfirmVerification = useCallback(() => {
        const code = formRef.current.getFieldValue('code_verification');

        if (verificationCode === code) {
            navigate('/dashboard');
        } else {
            toast({
                title: 'Código Inválido',
                description: 'Teste novamente...',
                status: 'error',
                duration: 4000,
                isClosable: true
            });
        }
    }, [verificationCode]);

    const handleSubmitLogin = useCallback(
        async (dataSubmit: DataSubmit) => {
            setLoading(true);
            try {
                const { email, password } = dataSubmit;
                if (email && password) {
                    const {
                        success: isAuthenticate,
                        isNewDevice,
                        accountVerification
                    } = await signIn({ email, password });

                    if (isNewDevice) {
                        setVerificationDevice(true);
                        setVerificationCode(accountVerification.verification_code);
                    }

                    if (isAuthenticate && !isNewDevice) navigate('/dashboard');
                } else {
                    toast({
                        title: 'Campos obrigatório',
                        description: 'Informe o email e senha ',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    });
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Usuario não encontrado',
                    description: 'Email ou senha incorretos',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });
            }
        },
        [navigate, signIn]
    );

    return (
        <Flex width="100%" height="100vh">
            <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="blue.700"
                height="100%"
                flexDirection="column"
                p="16px"
            >
                <Flex
                    width="100%"
                    maxWidth="480px"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmitLogin}
                        style={{
                            width: '100%'
                        }}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <Flex
                            width="100%"
                            flexDirection="column"
                            px="40px"
                            py="32px"
                            fontWeight="500"
                            color="purple.500"
                            backgroundColor="white"
                            rounded="8px"
                        >
                            <Box
                                mb="24px"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Image src={logo} alt="Logo E-Florista" width="200px" />
                                <Text fontSize="14px" fontWeight="400" whiteSpace="nowrap">
                                    Sistema para floriculturas
                                </Text>
                            </Box>

                            {!verificationDevice && (
                                <Heading fontSize="24px" textAlign="center">
                                    Faça seu login
                                </Heading>
                            )}

                            {!verificationDevice && (
                                <Input name="email" size="md" placeholder="Seu email" mt="24px" />
                            )}

                            {!verificationDevice && (
                                <Input
                                    name="password"
                                    typeInput="password"
                                    size="md"
                                    placeholder="Sua senha"
                                />
                            )}

                            {verificationDevice && (
                                <Flex flexDir="column" style={{ gap: '8px' }}>
                                    <Text fontWeight="400">
                                        Enviamos um um código para seu telefone, confirme o código
                                        abaixo
                                    </Text>
                                    <VerificationCodeInput name="verification_code" />

                                    <Flex width="100%" justifyContent="flex-end" mt="16px">
                                        <Button
                                            type="button"
                                            width="100%"
                                            maxWidth="120px"
                                            fontSize="16px"
                                            py="8px"
                                            backgroundColor="purple.500"
                                            _hover={{
                                                backgroundColor: 'green.500'
                                            }}
                                            onClick={() => handleConfirmVerification()}
                                        >
                                            Confirmar
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}

                            {!verificationDevice && (
                                <Flex justifyContent="space-between">
                                    <Box width="50%">
                                        <Checkbox
                                            name="remember_password"
                                            size="sm"
                                            variantColor="pink"
                                        >
                                            Lembrar senha
                                        </Checkbox>

                                        <PseudoBox
                                            onClick={() => handleToForgotPassword()}
                                            _hover={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Text fontSize="12px" color="gray.600">
                                                Esqueci minha senha
                                            </Text>
                                        </PseudoBox>
                                    </Box>

                                    <Flex width="50%" justifyContent="flex-end">
                                        <Button
                                            type="button"
                                            width="100%"
                                            maxWidth="120px"
                                            fontSize="16px"
                                            py="8px"
                                            backgroundColor="purple.500"
                                            _hover={{
                                                backgroundColor: 'green.500'
                                            }}
                                            onClick={() => formRef.current.submitForm()}
                                        >
                                            Entrar
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>
                    </Form>
                </Flex>
                {loading && <Loader />}
            </Flex>
        </Flex>
    );
};

export default SignIn;
