import { Input as ChakraInput, Flex } from '@chakra-ui/core';
import { useField } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import Input from '../Input';

interface VerificationCodeInputProps {
    name: string;
}

const VerificationCodeInput: React.FC<VerificationCodeInputProps> = ({ name }) => {
    const { fieldName, registerField } = useField(name);
    const inputRef = useRef<HTMLInputElement>(null);

    const [digits, setDigits] = useState(['', '', '', '', '', '']);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            path: 'value'
            // getValue: () => digits.join(''),
            // setValue: (_, value) => {
            //     const code = value ? value.toString().replace(/[^0-9]/g, '') : '';
            //     const newDigits = code.split('').slice(0, 6);
            //     const filledDigits = [...newDigits, ...Array(6 - newDigits.length).fill('')];
            //     setDigits(filledDigits);
            // },
            // clearValue: () => {
            //     setDigits(['', '', '', '', '', '']);
            // }
        });
    }, [fieldName, registerField]);

    useEffect(() => {
        console.log(digits);
    }, []);

    const handleChange = (index: number, value: string) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        if (numericValue === digits[index]) return;

        const newDigits = [...digits];
        newDigits[index] = numericValue.slice(-1);
        setDigits(newDigits);

        if (numericValue && index < 5) {
            const nextInput = document.querySelector<HTMLInputElement>(
                `input[name="${fieldName}-${index + 1}"]`
            );
            nextInput?.focus();
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
            if (digits[index] === '' && index > 0) {
                const prevInput = document.querySelector<HTMLInputElement>(
                    `input[name="${fieldName}-${index - 1}"]`
                );
                prevInput?.focus();
            } else {
                const newDigits = [...digits];
                newDigits[index] = '';
                setDigits(newDigits);
            }
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text/plain').replace(/[^0-9]/g, '');
        let currentIndex = index;
        const newDigits = [...digits];

        for (const char of pasteData) {
            if (currentIndex >= 6) break;
            newDigits[currentIndex] = char;
            currentIndex++;
        }

        setDigits(newDigits);
        console.log(pasteData, newDigits);
        const lastIndex = Math.min(index + pasteData.length, 5);
        const lastInput = document.querySelector<HTMLInputElement>(
            `input[name="${fieldName}-${lastIndex}"]`
        );
        lastInput?.focus();
    };

    return (
        <Flex style={{ gap: '10px' }}>
            {digits.map((digit, index) => (
                <ChakraInput
                    key={index}
                    name={`${fieldName}-${index}`}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={(e) => handlePaste(e, index)}
                    maxLength={1}
                    px="8px"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    textAlign="center"
                    width="40px"
                />
            ))}

            <Input name="code_verification" typeInput="hidden" value={digits.join('')} />
        </Flex>
    );
};

export default VerificationCodeInput;
